$max-page-width: 600pt;
$smart-header-cutoff: 500pt;
$image-list-width: 100pt;
$image-list-height: 56.25pt;
$image-list-spacing: 3pt;
$color: rgb(88, 127, 255);
$secondaryColor: red;

body {
  font-family: arial, lucida, tahoma, helvetica, verdana;
  max-width: $max-page-width;
  margin-right: auto;
  margin-left: auto;
  overflow-y: scroll;
  /* Always show the scrollbar so there is no jump when going to oeuvre */
  overflow-x: hidden;
  padding: 7pt;
  hyphens: auto;
}

header {
  position: relative;
  margin: 18.75pt 0;
  text-align: left;
  hyphens: none;

  #title {
    width: 100%;
    display: block;
    color: black;
    font-family: Helvetica, Arial, sans-serif;
    text-decoration: none;
    text-align: justify;

    #title_full {
      width: 101%;
      margin-left: -0.5%;

      @media(max-width: $smart-header-cutoff) {
        visibility: hidden;
        height: 0;
      }

      #title_full_mjb {
        font-size: 4.035em;
        display: block;
        white-space: nowrap;
      }
    }

    #title_parts {
      width: 102.5%;
      margin-left: -1.5%;

      @media(min-width: $smart-header-cutoff) {
        visibility: hidden;
        height: 0;
      }

      #title_part_mj,
      #title_part_b {
        display: block;
        white-space: nowrap;
      }

      #title_part_mj {
        font-size: 16.05vw;
      }

      #title_part_b {
        font-size: 16.75vw;
      }
    }
  }
}

a:link,
a:visited {
  color: $color;
}

a:hover,
a:active {
  color: $secondaryColor;
}

.nav {
  font-size: 0.8125em;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  gap: 12pt;
  align-items: stretch;
  text-align: center;

  a {
    color: #777;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    margin: -15pt 0;
    padding: 15pt 0;

    &.current,
    &:hover {
      div {
        color: #000;
        border-bottom: 2px solid $color;
      }
    }
  }
}

.work_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: $image-list-spacing;

  .work_item {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100% / 16 * 9;

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.texts_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $image-list-spacing;

  @media(max-width: $smart-header-cutoff) {
    grid-template-columns: 1fr;
  }

  .texts_item {
    text-align: center;

    border-style: solid;
    border-width: 1px;
    border-color: $color;

    .author {
      font-size: 150%;
      margin-top: 18pt;
      font-weight: bold;
    }

    .title {
      font-style: italic;
      font-size: 1.2em;
      line-height: 1.5em;
      min-height: 3em;
    }

    .publication_date {
      margin-top: 2pt;
    }

    .publication {
      margin-bottom: 8pt;
      font-size: 1em;
      line-height: 1.2em;
      min-height: 2.4em;
    }

    a {
      padding-left: 4pt;
      padding-right: 4pt;
    }
  }
}

#shown_media {
  position: relative;
  width: 100%;
  padding-bottom: 100% / 16 * 9;

  &>* {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.media_list {
  padding-top: 3pt;

  .media_item {
    display: inline-block;

    &>* {
      pointer-events: none;
      display: block;
      max-width: $image-list-width;
      height: $image-list-height;
    }

    &.image>img {
      width: $image-list-height;
      overflow: hidden;
      object-fit: cover;
      object-position: center;
    }
  }
}

.work_title {
  font-size: 1.125em;
  margin: 25px 0 10px;
  font-weight: normal;
}

.content {
  text-align: justify;
  font-size: 0.75em;
  color: #222;
  line-height: 175%;

  .logo {
    width: 1.2in;
  }
}

#english_content {
  display: unset;
}

#dutch_content {
  display: none;
}

.language_buttons {
  margin-top: 10pt;

  .language_button {
    display: inline-block;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 0.625em;

    &.current,
    &:hover {
      color: #000;
      border-top: 1px solid $color;
    }
  }
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -90;
}

.background_media {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  pointer-events: none;
  overflow: hidden;

  &>* {
    width: 100vw;
    height: 9/16*100vw; // To ensure 16/9 aspect ratio
    min-height: 100vh;
    min-width: 16/9*100vh; // To ensure 16/9 aspect ratio
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}